import { Tractor } from "@deathstar/types/northstar";
import { EquipmentRow } from "@deathstar/types/waypoint";
import { Checkbox } from "@deathstar/ui";
import { Dialog } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import api from "../../api/api";
import { useAccountId } from "../../api/useAccountId";

export function TractorLinker({
    open,
    currentValue,
    onSelect,
    onUnselect,
    onClose,
}: {
    open: boolean;
    currentValue: number | null | undefined;
    onSelect(tractor: EquipmentRow<Tractor>): unknown;
    onUnselect(): unknown;
    onClose(): void;
}) {
    const accountId = useAccountId();
    const { data } = api.equipment.useFindTractors(accountId!);

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: "p-4 max-w-none" }}>
            <table className="table-auto text-sm col-spacing-4 row-p-2 row-spacing-2">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Unit #</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Year</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Make</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">Type</th>
                        <th className="text-left text-xs font-light uppercase text-gray-500">VIN</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((tractor) => (
                        <tr
                            key={tractor.id}
                            className="cursor-pointer hover:bg-gray-100 [&>td:first-child]:rounded-l [&>td:last-child]:rounded-r"
                            onClick={async () => {
                                if (tractor.id === currentValue) {
                                    onUnselect();
                                } else {
                                    onSelect(tractor);
                                }
                            }}
                        >
                            <td>
                                <Checkbox
                                    classes={{ input: "w-4 h-4" }}
                                    checked={tractor.id === currentValue}
                                    readOnly
                                    autoFocus={tractor.id === currentValue}
                                />
                            </td>
                            <td>{tractor.unitNumber}</td>
                            <td>{tractor.year}</td>
                            <td>{tractor.make}</td>
                            <td>{tractor.type?.name}</td>
                            <td>{tractor.vin}</td>
                        </tr>
                    )) || (
                        <>
                            <tr>
                                <td colSpan={6}>
                                    <Skeleton variant="rect" width="100%" height={32} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={6}>
                                    <Skeleton variant="rect" width="100%" height={32} />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={6}>
                                    <Skeleton variant="rect" width="100%" height={32} />
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </Dialog>
    );
}
