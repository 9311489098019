import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import api from "../../api/api";
import { blazar } from "../../api/util/blazar";
import { NotFoundError } from "../../api/util/exceptions";
import src from "../../assets/images/mail-sent.svg";
import Logo from "../../components/logo/Logo";
import SplashScreen from "../../pages/sol/SplashScreen";
import { HELP_CENTER_URL } from "../../util/constants";
import MyInvites from "../settings/profile/MyInvites";

export default function MemberSetup() {
    const auth0 = useAuth0();
    const auth0User = auth0.user!;
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const { data: user, isLoading, error } = api.user.useMe();

    useEffect(() => {
        if (user && user.memberships?.length) {
            navigate(query.get("redirect") || "/sol");
        }
    }, [user, navigate, query]);

    const resendEmailVerification = useMutation({
        mutationFn: async () => {
            await blazar.fetch("waypoint/users/me/resend-email-verification", { method: "POST" });
        },
    });

    const {
        mutate: autoSetup,
        isPending: isSettingUpUser,
        isSuccess: isSettingUpUserSuccess,
    } = useMutation({
        mutationFn: () => blazar.fetch("waypoint/users/me/auto-setup", { method: "POST" }),
        onSuccess: () => {
            navigate({ pathname: query.get("redirect") || "/sol" });
        },
    });

    useEffect(() => {
        const int = setInterval(() => {
            if (error instanceof NotFoundError && !isLoading && !user && !isSettingUpUserSuccess) {
                autoSetup();
            }
        }, 5_000);
        return () => clearInterval(int);
    }, [autoSetup, error, isLoading, isSettingUpUserSuccess, user]);

    if (user?.memberships?.length) {
        const id = user.lastVisitedAccountId || user.memberships[0].accountId;
        return <Navigate to={`/sol/${id}`} />;
    }

    if (!user || isSettingUpUser) {
        return (
            <div className="p-8">
                <div className="mt-[20vh] flex h-full justify-evenly">
                    <div className="mt-24 max-w-xl space-y-8">
                        <p className="text-5xl font-bold text-navigator-primary-dark">Please check your email</p>

                        <p className="text-xl text-stone-600">
                            We've sent a link to verify your account to{" "}
                            <span className="font-medium text-navigator-primary">{auth0User.email}</span>. This is necessary before we can
                            continue.
                        </p>

                        <div className="space-y-8">
                            <p className="mt-24 text-base text-stone-600">Be sure to check your spam folder(s)</p>
                            <p className="text-base text-stone-600">
                                Can't seem to find the email?{" "}
                                <button
                                    className="inline font-medium text-navigator-primary disabled:opacity-50 enabled:hover:text-navigator-primary-dark"
                                    disabled={resendEmailVerification.isPending}
                                    onClick={() => {
                                        resendEmailVerification.mutate();
                                    }}
                                >
                                    Click here
                                </button>{" "}
                                to resend or check out our{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={HELP_CENTER_URL}
                                    className="font-medium text-navigator-primary hover:text-navigator-primary-dark"
                                >
                                    help guides
                                </a>{" "}
                                for more information.
                            </p>
                            {resendEmailVerification.isSuccess && (
                                <p className="text-base text-navigator-primary">Email sent successfully!</p>
                            )}
                        </div>
                    </div>
                    <img alt="Envelope with check mark" src={src} className="w-1/4 animate-grow-in" />
                </div>
            </div>
        );
    }

    if (isLoading) {
        return <SplashScreen />;
    }

    return (
        <div className="h-screen w-screen overflow-auto bg-waypoint-orange/10 p-4 lg:p-12">
            <div className="mx-auto flex h-full w-full max-w-prose flex-col gap-4 rounded-lg border-2 border-waypoint-orange bg-white p-4 py-6 md:h-max md:p-12 md:py-12">
                <div className="flex justify-center">
                    <Logo />
                </div>
                <p className="text-navigator-primary-dark">
                    It looks like your email address is not associated with any of our, or the FMCSA's, records. Don't worry, there are
                    still a few ways for you to join Waypoint!
                </p>
                <div className="my-8 flex justify-center">
                    <MyInvites className="!items-center" />
                </div>
                <p className="mt-8 text-stone-600">
                    If you want to join a coworker who is already on Waypoint, ask them to invite you to their organization.
                </p>
                <div className="flex grow flex-col justify-end">
                    <p className="mt-8 text-xs text-stone-600">
                        If you were looking to set up a new organization, check to see if your email matches what was entered on your
                        MCS150; you may need to set up an account with that email instead.
                    </p>
                </div>
            </div>
        </div>
    );
}
