import { DriverDto, DriverRow, EquipmentDriverChangeDto } from "@deathstar/types/waypoint";
import { QueryKey, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import QueryString from "qs";
import { queryClient } from "../../util/queryClient";
import { blazar } from "../util/blazar";
import { ResponseError } from "../util/exceptions";
import { monthlyReports } from "./monthlyReports";

export const drivers = {
    queryKeys: {
        find: (accountId: string) => [accountId, "drivers"] as QueryKey,
    },

    find: (accountId: string) => {
        return blazar.fetchJson<DriverRow[]>(`/waypoint/orgs/${accountId}/drivers`);
    },

    useFind: <T = DriverRow[]>(accountId: string, config?: Partial<UseQueryOptions<DriverRow[], ResponseError, T>>) =>
        useQuery<DriverRow[], ResponseError, T>({
            queryKey: drivers.queryKeys.find(accountId),
            queryFn: () => drivers.find(accountId),
            enabled: !!accountId,
            retry: (count, error) => {
                return count < 3 && error.status !== 403 && error.status !== 429;
            },
            ...config,
        }),

    create: async (accountId: string, body: DriverDto) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/drivers`, {
            method: "POST",
            body: JSON.stringify(body),
        });
    },

    useCreate: (accountId: string, config?: UseMutationOptions<void, ResponseError, DriverDto>) => {
        return useMutation<void, ResponseError, DriverDto>({
            mutationFn: (data) => {
                return drivers.create(accountId, data);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: drivers.queryKeys.find(accountId) });
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },

    requestChange: async (accountId: string, id: number, data: Omit<EquipmentDriverChangeDto, "coverages">) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/drivers/${id}`, {
            method: "PATCH",
            body: JSON.stringify(data),
        });
    },

    useRequestChange: (
        accountId: string,
        config?: UseMutationOptions<void, ResponseError, { id: number } & Omit<EquipmentDriverChangeDto, "coverages">>
    ) => {
        return useMutation({
            mutationFn: ({ id, ...data }) => {
                return drivers.requestChange(accountId, id, data);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: drivers.queryKeys.find(accountId) });
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },

    update: async (accountId: string, id: number, changes: Partial<Pick<DriverDto, "tractorId">>) => {
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/drivers/${id}`, {
            method: "PATCH",
            body: JSON.stringify(changes),
        });
    },

    requestRemoval: async (accountId: string, id: number, requestDate: string, policyId?: string) => {
        const params = QueryString.stringify(
            {
                requestDate,
                policyId,
            },
            { addQueryPrefix: true }
        );
        return await blazar.fetchJson<void>(`/waypoint/orgs/${accountId}/drivers/${id}${params}`, {
            method: "DELETE",
        });
    },

    useRequestRemoval: (
        accountId: string,
        config?: UseMutationOptions<void, ResponseError, { id: number; requestDate: string; policyId?: string }>
    ) => {
        return useMutation({
            mutationFn: ({ id, requestDate, policyId }) => {
                return drivers.requestRemoval(accountId, id, requestDate, policyId);
            },
            ...config,
            onSuccess: (...args) => {
                queryClient.invalidateQueries({ queryKey: drivers.queryKeys.find(accountId) });
                queryClient.invalidateQueries({ queryKey: monthlyReports.queryKeys.find(accountId) });
                config?.onSuccess?.(...args);
            },
        });
    },
};
