/**
 * Print an element to the browser's printer dialog. Creates an iFrame and inserts the HTML of the given element into it.
 */
export async function printElement(
    _element: HTMLElement,
    _options: { includeDotAnalysisStylesheet?: boolean } = { includeDotAnalysisStylesheet: false }
): Promise<void> {
    return new Promise((res, rej) => {
        const element = _element.cloneNode(true) as HTMLElement;
        const iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        iframe.style.visibility = "hidden";
        iframe.style.height = "0px";
        iframe.style.width = "0px";

        if (!iframe.contentDocument) {
            rej("Could not load iframe");
            return;
        }

        for (const sheet of document.styleSheets || []) {
            // const isDotAnalysisStylesheet = Array.from(sheet.cssRules).some((rule) => {
            //     if (rule.cssText.startsWith("#dot-analysis")) {
            //         return true;
            //     }
            //     return false;
            // });

            // if (!options.includeDotAnalysisStylesheet && isDotAnalysisStylesheet) {
            //     continue;
            // } else if (options.includeDotAnalysisStylesheet && !isDotAnalysisStylesheet) {
            //     continue;
            // }

            // Do not inject CSS from extensions
            if (sheet.href?.startsWith("chrome-extension://")) continue;
            const style = document.createElement("style");
            for (const rule of sheet.cssRules) {
                style.innerHTML += rule.cssText + "\n";
            }
            iframe.contentDocument.head.appendChild(style);
        }

        iframe.contentDocument.body.appendChild(element);
        iframe.contentDocument.body.id = "_pdf_root";

        let i = 0;
        const int = setInterval(() => {
            if (iframe.contentDocument?.readyState === "complete" && iframe.contentDocument.fonts.status === "loaded") {
                iframe.contentWindow?.print();
                setTimeout(() => {
                    document.body.removeChild(iframe);
                }, 1000);
                res();
                clearInterval(int);
            } else {
                i += 100;
                if (i > 10_000) {
                    clearInterval(int);
                    document.body.removeChild(iframe);
                    rej("Could not load document");
                }
            }
        }, 100);
    });
}
