import { Trailer } from "@deathstar/types/northstar";
import { EquipmentRow } from "@deathstar/types/waypoint";
import { ActionButton, DataTable, fuzzyFilter, Tabs } from "@deathstar/ui";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import Loader from "../../components/loader/Loader";
import EquipmentColumns from "./EquipmentColumns";
import { NewUnitDialog } from "./NewUnitDialog";

const columns = [
    EquipmentColumns.mobileTrailerCell,
    EquipmentColumns.pending,
    EquipmentColumns.upcomingDate,
    EquipmentColumns.unitNumber,
    EquipmentColumns.year,
    EquipmentColumns.make,
    EquipmentColumns.type,
    EquipmentColumns.vin,
    EquipmentColumns.value,
    EquipmentColumns.ownerOperator,
    EquipmentColumns.coverages,
    EquipmentColumns.additionalInterests,
    EquipmentColumns.trailerActions,
] as ColumnDef<EquipmentRow<Trailer>>[];

const AnimatedDataTable = animated(DataTable<EquipmentRow<Trailer>>);

export default function TrailersTable({ data }: { data: EquipmentRow<Trailer>[] | undefined }) {
    const [filter, setFilter] = useState<"all" | "company" | "owner-operator">("all");
    const [adding, setAdding] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([]);

    const memoizedData = useMemo(() => data || [], [data]);

    const spring = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        pause: !data,
    });

    const sortingState = useMemo(() => {
        return [{ id: "pending", desc: true }, ...sorting];
    }, [sorting]);

    const columnFilters = useMemo(() => {
        if (filter === "all") {
            return [];
        }
        return [{ id: "owner-operator", value: filter === "owner-operator" }];
    }, [filter]);

    const table = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        globalFilterFn: fuzzyFilter,
        getColumnCanGlobalFilter: () => true,
        state: {
            sorting: sortingState,
            columnFilters,
        },
        initialState: {
            pagination: {
                pageSize: 30,
            },
        },
        onSortingChange: setSorting,
    });

    return (
        <div>
            {data ? (
                <>
                    <AnimatedDataTable
                        style={spring}
                        table={table}
                        headerRow={
                            <div className="flex items-end gap-4">
                                <Tabs
                                    tabs={[
                                        {
                                            label: () => <p className="w-32 px-2 py-1">All trailers</p>,
                                            value: "all",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                        {
                                            label: () => <p className="w-32 px-2 py-1">Company</p>,
                                            value: "company",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                        {
                                            label: () => <p className="w-32 px-2 py-1">Owner Operators</p>,
                                            value: "owner-operator",
                                            buttonProps: { className: "z-[1]" },
                                        },
                                    ]}
                                    value={filter}
                                    onChange={setFilter}
                                    className="rounded-md bg-stone-100 py-1 shadow-inner"
                                    borderClasses="bg-white border border-waypoint-blue z-[0] shadow rounded-md"
                                />
                                <div className="grow"></div>
                                <ActionButton onClick={() => setAdding(true)}>
                                    <PlusIcon className="h-5 w-5" />
                                    Add trailer
                                </ActionButton>
                                <DataTable.Search table={table} />
                            </div>
                        }
                    />
                    <NewUnitDialog open={adding} onClose={() => setAdding(false)} unitType="trailers" />
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}
